<template>
  <div>
    <receive-infectious></receive-infectious>
    <bottom-button></bottom-button>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";
import { components } from "./options";

@Component({ components })
class Disclaimer extends Vue {
  static name = "Disclaimer";
}
export default Disclaimer;
</script>
<style lang="scss" src="../index.scss" scoped></style>
